import { Typography, Box } from "@mui/material";

export const TituloPagina = ({ texto, sx }) => {
  const defaultSx = {
    "-webkit-text-stroke": "2px black",
    textShadow: "-3px -2px 0px #000",
    fontWeight: "bold",
    marginTop: 4,
    marginBottom: 4,
    border: "1px black solid",
    borderRadius: "1.0em",
    p: 1,
    boxShadow: "3px 3px 0 black, 2px 2px 0 black, 1px 1px 0 black",
    backgroundColor: "rgba(255,255,255,1.0)",
    //"3px 3px 0 #000, -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000, 1px 1px 0 #000;",
  };
  const asx = Object.assign({}, defaultSx, sx || {});
  return (
    <Box sx={{ margin: 2 }}>
      <Typography
        component="h1"
        align="center"
        variant="h2"
        color="#edc6b2"
        sx={asx}
        gutterBottom
      >
        {texto}
      </Typography>
    </Box>
  );
};

export const SubTitulo = ({ texto }) => {
  return (
    <Box sx={{ margin: 3 }}>
      <Typography
        component="span"
        align="left"
        variant="h4"
        color="text.primary"
        sx={{
          marginTop: 2,
          marginBottom: 2,
          border: "1px black solid",
          borderRadius: "1.0em",
          px: 2,
          py: 1,
          backgroundColor: "white",
          boxShadow: "3px 3px 0 black, 2px 2px 0 black, 1px 1px 0 black",
        }}
        gutterBottom
      >
        {texto}
      </Typography>
    </Box>
  );
};

export const TextoGrande = ({ texto, align, sx }) => {
  const atxt = texto.map ? texto : [texto];
  return (
    <>
      {atxt.map((txt, i) => (
        <Typography
          key={i}
          sx={sx}
          variant="h5"
          align={align || "center"}
          color="text.secondary"
          paragraph
          gutterBottom
        >
          {txt}
        </Typography>
      ))}
    </>
  );
};

export const TextoParrafo = ({ texto, align, sx, asListItem }) => {
  const atxt = texto.length ? texto : [texto];
  const asx = sx || { fontSize: "18px" };
  return (
    <>
      {atxt.map((txt, i) =>
        asListItem ? (
          <li>
            <Typography
              key={i}
              variant="body1"
              align={align || "justify"}
              gutterBottom
              paragraph
              sx={asx}
            >
              {txt}
            </Typography>
          </li>
        ) : (
          <Typography
            key={i}
            variant="body1"
            align={align || "justify"}
            gutterBottom
            paragraph
            sx={asx}
          >
            {txt}
          </Typography>
        )
      )}
    </>
  );
};
