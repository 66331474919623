import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
// import PaginaInicio from "./components/paginaInicio";
import PaginaJuegos from "./components/paginaJuegos";
// import PaginaSalud from "./components/paginaSalud";
// import PaginaVideos from "./components/paginaVideos";
// import PaginaArte from "./components/paginaArte";
// import PaginaServicios from "./components/paginaServicios";
import PaginaContacto from "./components/paginaContacto";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <Routes>
        <Route path="" element={<Navigate to="/Inicio" />} />
        <Route path="/" element={<App />}>
          <Route path="Inicio" element={<PaginaJuegos />} />
          {/* <Route path="Juegos" element={<PaginaJuegos />} />
        <Route path="Salud" element={<PaginaSalud />} />
        <Route path="Videos" element={<PaginaVideos />} />
        <Route path="Servicios" element={<PaginaServicios />} />
        <Route path="Creacion" element={<PaginaArte />} /> */}
          <Route path="Contacto" element={<PaginaContacto />} />
          <Route path="*" element={<Navigate to="Inicio" />} />
        </Route>
      </Routes>
    </BrowserRouter>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
